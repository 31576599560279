import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteRight, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Carousel from '../components/Carousel'
import BackToTopButton from '../components/BackToTopButton'
import Link from '../components/LinkWrapper'
import i18n from '../locales/he.yaml'
import { getParams } from '../utils'

const HomepageQuery = graphql`
  query HomepageQuery {
    logo: file(relativePath: { eq: "logo-academy-circle.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    candies: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-ממתקים-ומפלצות-מפוסלות-בבצק-סוכר-על-ערימת-ספלים.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    safariAnimals: file(
      relativePath: {
        eq: "עוגת-ג׳יפ-של-חיות-הספארי-אריה-ג׳ירפה-זברה-היפופוטם-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          blurredOptions: { width: 80 }
          width: 600
          layout: CONSTRAINED
        )
      }
    }
    circus: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הקרקס-קוף-פיל-נמר-כלב-ים-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          blurredOptions: { width: 80 }
          width: 600
          layout: CONSTRAINED
        )
      }
    }
    podcast: file(relativePath: { eq: "קארין-עמנואל-פודקאסט.JPG" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          blurredOptions: { width: 80 }
          width: 600
          layout: CONSTRAINED
        )
      }
    }
  }
`

const IndexTemplate = ({ pathname, images }) => {
  const { candies, safariAnimals, circus, podcast } = images
  const PRODUCT_IMAGE = [safariAnimals, circus, podcast]
  return (
    <>
      <BackToTopButton selectorId='products' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-bottom-0'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column is-12-mobile is-10-tablet is-10-desktop'>
                <h1 className='is-size-4 has-text-nude'>
                  {i18n.homepage.subtitle}
                </h1>
                <h2 className='is-size-4'>{i18n.homepage.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body is-padding-top-0'>
          <div className='container content'>
            <div className='columns is-multiline is-mobile is-centered is-vcentered'>
              <div className='column is-10-mobile is-8-tablet is-5-desktop'>
                <GatsbyImage
                  image={getImage(candies)}
                  title={i18n.homepage.mainImage.title}
                  alt={i18n.homepage.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(circus).width,
                    margin: '0 auto' // used to center the image
                  }}
                  loading='eager'
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.homepage.mainImage.title}
                  </p>
                </div>
              </div>
              <div className='column is-6-desktop is-full-tablet is-full-mobile is-offset-1-desktop'>
                <h3 className='is-size-5'>{i18n.homepage.description.title}</h3>
                <p>{i18n.homepage.description.text}</p>
                <p>
                  <Link
                    to='/about'
                    className='button is-nude is-outlined has-text-weight-bold'
                  >
                    {i18n.homepage.description.readMore}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='products' className='hero has-background-nude-light'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <h3>{i18n.homepage.products.title}</h3>
          </div>
        </div>
      </div>
      {i18n.homepage.products.bullets.map((bullet, index) => (
        <div key={index} className='hero'>
          <div className='hero-body'>
            <div className='container'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-mobile is-10-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <div className='content'>
                    <h3>{bullet.title}</h3>
                    <p>{bullet.description}</p>
                    <p className='has-text-centered-mobile is-margin-top-6-mobile is-margin-bottom-4-mobile'>
                      <Link
                        to={`${pathname}${
                          index === 0
                            ? 'cakes/'
                            : index === 1
                            ? 'academy/'
                            : 'academy/podcast/'
                        }`}
                        className='button is-medium is-nude'
                      >
                        {bullet.callToAction}
                      </Link>
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    'column is-10-mobile is-8-tablet is-6-desktop is-5-widescreen',
                    {
                      'is-offset-1-widescreen': index % 2 === 0
                    }
                  )}
                >
                  <GatsbyImage
                    image={getImage(PRODUCT_IMAGE[index])}
                    title={bullet.image.title}
                    alt={bullet.image.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(getImage(PRODUCT_IMAGE[index])).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {bullet.image.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className='hero'>
        <div className='hero-body is-padding-bottom-0'>
          <div className='container content has-text-centered'>
            <h3>{i18n.homepage.testimonialsTitle}</h3>
          </div>
        </div>
      </div>
      <Carousel>
        {i18n.homepage.testimonials.map((testimony) => (
          <div key={testimony.title} className='hero'>
            <div className='hero-body'>
              <div className='container content has-text-centered'>
                <div className='columns is-centered'>
                  <div className='column is-6-widescreen is-8-desktop is-10-tablet is-12-mobile'>
                    <div
                      className={clsx(
                        'columns is-flex-direction-column is-mobile is-align-items-center',
                        { 'direction-column-reverse': testimony.isEnglish }
                      )}
                    >
                      <div className='column is-align-self-flex-start'>
                        <FontAwesomeIcon icon={faQuoteRight} size='lg' />
                      </div>
                      <div
                        className='column is-padding-top-0 is-padding-bottom-0'
                        dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                      >
                        <h4 className='has-text-gold-black is-margin-bottom-0'>
                          {testimony.title}
                        </h4>
                      </div>
                      <div className='column is-align-self-flex-end'>
                        <FontAwesomeIcon icon={faQuoteLeft} size='lg' />
                      </div>
                    </div>
                    <div
                      className={clsx('columns is-justify-content-center', {
                        'direction-row-reverse': testimony.isEnglish
                      })}
                    >
                      <div className='column is-narrow'>
                        <p className='has-text-centered has-text-weight-bold'>
                          {testimony.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  )
}

const Index = ({ location: { pathname, search } }) => {
  const data = useStaticQuery(HomepageQuery)
  const params = getParams(search)
  /* backwards compatibility for published links */
  if (params.ns_url && params.mid) {
    window.location.href = `/core/wp/?ns_url=${params.ns_url}&mid=${params.mid}`
    return null
  }
  if (params.ff_landing) {
    window.location.href = `/core/wp/?ff_landing=${params.ff_landing}`
    return null
  }
  if (params.fluentcrm && params.route && params.ce_id && params.secure_hash) {
    window.location.href = `/core/wp/?fluentcrm=${params.fluentcrm}&route=${params.route}&ce_id=${params.ce_id}&secure_hash=${params.secure_hash}`
    return null
  }
  /* backwards compatibility for published links */

  return (
    <Layout>
      <Seo
        metadata={i18n.homepage.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.candies),
          alt: i18n.homepage.mainImage.alt
        }}
        schemaMarkup={{
          organization: {
            image: getSrc(data.candies),
            logo: getSrc(data.logo)
          }
        }}
      />
      <IndexTemplate pathname={pathname} images={data} />
    </Layout>
  )
}

export default Index
