import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPodcast } from '@fortawesome/free-solid-svg-icons'
import {
  faCirclePlay,
  faEnvelope,
  faPaperPlane
} from '@fortawesome/free-regular-svg-icons'
import {
  faSpotify,
  faYoutube,
  faFacebookF,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import Layout from './Layout'
import Seo from './Seo'
import Link, { navigate } from './LinkWrapper'
import ScrollOnAnimation from './ScrollOnAnimation'
import i18n from '../locales/he.yaml'
import { fireEvent } from '../analytics'

const PodcastQuery = graphql`
  query PodcastQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    podcastKarinn: file(relativePath: { eq: "קארין-עמנואל-פודקאסט.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    podcastLogo: file(
      relativePath: { eq: "180-מעלות-הפודקאסט-של-קארין-עמנואל-לוגו.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const PodcastTemplate = ({ pageContext: data, location: { pathname } }) => {
  const {
    site: {
      siteMetadata: { siteUrl }
    },
    podcastKarinn,
    podcastLogo,
    survival
  } = useStaticQuery(PodcastQuery)

  const product = {
    category: i18n.podcast.title,
    name: i18n.podcast.title
  }

  return (
    <Layout product={product}>
      <Seo
        metadata={i18n.podcast.metadata}
        pathname={pathname}
        og={{
          image: getSrc(podcastKarinn),
          alt: i18n.podcast.image.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            { name: i18n.header.podcast, path: '/academy/podcast/' }
          ],
          product: {
            name: i18n.podcast.title,
            sku: i18n.podcast.title,
            description: i18n.podcast.title,
            brand: i18n.header.academy,
            image: getSrc(podcastKarinn),
            path: `/academy/podcast/`,
            price: '0'
          }
        }}
      />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <a href='#' aria-current='page'>
                    {i18n.header.podcast}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='columns is-multiline is-mobile is-centered'>
              <div className='column is-full-real-mobile is-10-mobile is-8-tablet is-5-desktop'>
                <GatsbyImage
                  image={getImage(podcastKarinn)}
                  title={i18n.podcast.image.title}
                  alt={i18n.podcast.image.alt}
                  className='live-image'
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.podcast.image.title}
                  </p>
                </div>
              </div>
              <div className='column is-6-desktop is-full-tablet is-full-mobile is-offset-1-desktop is-flex is-flex-direction-column is-align-items-center is-justify-content-center'>
                <h2 className='is-size-1 is-margin-top-0 is-margin-bottom-0 is-family-handwrite'>
                  {i18n.podcast.name}
                </h2>
                <h1 className='is-size-1 is-margin-top-0 is-family-handwrite'>
                  {i18n.podcast.description}
                </h1>
                <h3 className='is-size-3 is-margin-top-0 has-text-nude is-family-handwrite has-text-weight-normal'>
                  {i18n.podcast.suffix}
                </h3>
                <p className='is-margin-top-4'>{i18n.podcast.channelsPrefix}</p>
                <p className='buttons is-centered'>
                  <a
                    className='button'
                    href={data.spotify.link}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() =>
                      fireEvent('ExternalClick', product, {
                        to: data.spotify.link
                      })
                    }
                  >
                    <span className='icon has-text-spotify'>
                      <FontAwesomeIcon icon={faSpotify} />
                    </span>
                    <span>{i18n.podcast.channels.spotify}</span>
                  </a>
                  <a
                    className='button'
                    href={data.apple.link}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() =>
                      fireEvent('ExternalClick', product, {
                        to: data.apple.link
                      })
                    }
                  >
                    <span className='icon has-text-apple-podcast'>
                      <FontAwesomeIcon icon={faPodcast} />
                    </span>
                    <span>{i18n.podcast.channels.apple}</span>
                  </a>
                  <a
                    className='button'
                    href={data.youtube.link}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() =>
                      fireEvent('ExternalClick', product, {
                        to: data.youtube.link
                      })
                    }
                  >
                    <span className='icon has-text-youtube'>
                      <FontAwesomeIcon icon={faYoutube} />
                    </span>
                    <span>{i18n.podcast.channels.youtube}</span>
                  </a>
                </p>
                <p className='help has-text-centered'>{i18n.podcast.share}</p>
                <p className='buttons are-small is-centered'>
                  <a
                    className='button is-whatsapp is-inverted'
                    href={`whatsapp://send?text=${encodeURIComponent(
                      siteUrl + pathname
                    )}`}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() =>
                      fireEvent('ExternalClick', product, {
                        to: 'whatsapp'
                      })
                    }
                  >
                    <span className='icon'>
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </span>
                  </a>
                  <a
                    className='button is-facebook is-inverted'
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      siteUrl + pathname
                    )}`}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() =>
                      fireEvent('ExternalClick', product, {
                        to: 'facebook'
                      })
                    }
                  >
                    <span className='icon'>
                      <FontAwesomeIcon icon={faFacebookF} />
                    </span>
                  </a>
                  <a
                    className='button is-dark is-inverted'
                    href={`mailto:?subject=${i18n.podcast.title}&body=${
                      siteUrl + pathname
                    }`}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() =>
                      fireEvent('ExternalClick', product, {
                        to: 'email'
                      })
                    }
                  >
                    <span className='icon'>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                  </a>
                  <a
                    className='button is-dark is-inverted'
                    onClick={async () => {
                      try {
                        await navigator.share({
                          title: i18n.podcast.title,
                          text: i18n.podcast.title,
                          url: siteUrl + pathname
                        })
                        fireEvent('ExternalClick', product, {
                          to: 'share'
                        })
                      } catch (_) {
                        void 0
                      }
                    }}
                  >
                    <span className='icon'>
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </span>
                  </a>
                </p>
                <p>
                  <Link
                    className='button is-nude is-outlined'
                    to={`/academy/podcast/episodes/${
                      data.spotify.episodes.length - 1
                    }/`}
                  >
                    {i18n.podcast.lastEpisodeListen}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='columns is-multiline is-mobile is-centered'>
              <div className='column is-6-desktop is-full-tablet is-full-mobile is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.podcast.html_description
                  }}
                />
                <p className='is-margin-top-6'>
                  <Link
                    className='button is-nude is-outlined'
                    to={`/academy/podcast/episodes/0/`}
                  >
                    {i18n.podcast.trailerListen}
                  </Link>
                </p>
              </div>
              <div className='column is-full-real-mobile is-10-mobile is-8-tablet is-5-desktop is-offset-1-widescreen'>
                <GatsbyImage
                  image={getImage(podcastLogo)}
                  title={i18n.podcast.image.title}
                  alt={i18n.podcast.image.alt}
                  className='live-image'
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.podcast.imageLogo.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='is-margin-bottom-4'>
              <h3 className='mb-6 has-text-centered'>
                {i18n.podcast.latestEpisodes}
              </h3>
              <div className='columns is-multiline'>
                {data.spotify.episodes.map((episode, index) => (
                  <ScrollOnAnimation key={index}>
                    <div className='column is-3-widescreen is-4-desktop is-6-tablet is-10-mobile is-offset-1-mobile'>
                      <div
                        className='card is-clickable is-flex is-flex-direction-column hoverable'
                        style={{ height: '100%' }}
                        onClick={() => {
                          navigate(
                            `/academy/podcast/episodes/${
                              data.spotify.episodes.length - index - 1
                            }/`
                          )
                        }}
                      >
                        <div className='course-progress-tag is-margin-top-4'>
                          <span className='tag is-nude is-light'>
                            {episode.name.split(':')[0]}
                          </span>
                        </div>
                        <div className='card-image'>
                          <figure className='image is-1by1 is-fullwidth is-margin-left-0 is-margin-right-0'>
                            <img src={episode.images[0].url} />
                          </figure>
                        </div>
                        <div
                          className='card-content is-flex-grow-1'
                          style={{ padding: '1rem' }}
                        >
                          <p className='help'>
                            {new Date(episode.release_date).toLocaleDateString(
                              'he-IL',
                              {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              }
                            )}{' '}
                            •{' '}
                            {new Date(episode.duration_ms).toLocaleTimeString(
                              'he-IL',
                              {
                                hour12: false,
                                timeZone: 'UTC',
                                hour: 'numeric',
                                minute: 'numeric'
                              }
                            )}
                          </p>
                          <p>{episode.name}</p>
                        </div>
                        <div className='card-footer'>
                          <Link
                            className='card-footer-item has-text-centered is-justify-content-space-between'
                            to={`/academy/podcast/episodes/${
                              data.spotify.episodes.length - index - 1
                            }/`}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          >
                            {i18n.podcast.listenNow}
                            <FontAwesomeIcon icon={faCirclePlay} size='lg' />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </ScrollOnAnimation>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered'>
              <ScrollOnAnimation>
                <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                  <h3>{i18n.podcast.about.title}</h3>
                  <p>{i18n.podcast.about.description}</p>
                </div>
              </ScrollOnAnimation>
              <ScrollOnAnimation>
                <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen'>
                  <GatsbyImage
                    image={getImage(survival)}
                    title={i18n.podcast.about.mainImage.title}
                    alt={i18n.podcast.about.mainImage.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(survival).width,
                      margin: '0 auto' // used to center the image
                    }}
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {i18n.podcast.about.mainImage.title}
                    </p>
                  </div>
                </div>
              </ScrollOnAnimation>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PodcastTemplate
