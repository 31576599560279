import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPodcast,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import {
  faSpotify,
  faYoutube,
  faFacebookF,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import clsx from 'clsx'
import Layout from './Layout'
import Seo from './Seo'
import Link from './LinkWrapper'
import i18n from '../locales/he.yaml'
import { fireEvent } from '../analytics'

const EpisodeQuery = graphql`
  query EpisodeQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const EpisodeTemplate = ({ pageContext: episode, location: { pathname } }) => {
  const {
    site: {
      siteMetadata: { siteUrl }
    }
  } = useStaticQuery(EpisodeQuery)
  const [isVideo, setIsVideo] = useState(false)

  const product = {
    category: i18n.podcast.title,
    name: `${i18n.podcast.title} - ${episode.spotify.name.split(':')[0]}`
  }

  return (
    <Layout product={product}>
      <Seo
        metadata={{
          lang: i18n.podcast.metadata.lang,
          title: episode.spotify.name,
          description: i18n.podcast.metadata.title
        }}
        pathname={pathname}
        pagination={{
          prev: episode.prev ? episode.prev.link : null,
          next: episode.next ? episode.next.link : null
        }}
        og={{
          image: episode.spotify.images[0].url,
          alt: ''
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            { name: i18n.header.podcast, path: '/academy/podcast/' },
            {
              name: episode.spotify.name,
              path: `/academy/podcast/episodes/${episode.index}/`
            }
          ],
          product: {
            name: episode.spotify.name,
            sku: episode.spotify.name,
            description: i18n.podcast.title,
            brand: i18n.header.academy,
            image: episode.spotify.images[0].url,
            path: `/academy/podcast/episodes/${episode.index}/`,
            price: '0'
          }
        }}
      />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li>
                  <Link to={'/academy/podcast/'}>{i18n.header.podcast}</Link>
                </li>
                <li className='is-active'>
                  <a href='#' aria-current='page'>
                    {episode.spotify.name.split(':')[0]}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body is-padding-top-0'>
          <div className='container content'>
            <p className='has-text-centered'>
              {new Date(episode.spotify.release_date).toLocaleDateString(
                'he-IL',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                }
              )}{' '}
              •{' '}
              {new Date(episode.spotify.duration_ms).toLocaleTimeString(
                'he-IL',
                {
                  hour12: false,
                  timeZone: 'UTC',
                  hour: 'numeric',
                  minute: 'numeric'
                }
              )}
            </p>
            <h4 className='has-text-centered'>{episode.spotify.name}</h4>
            <p className='buttons are-small is-centered is-margin-bottom-4'>
              <a
                className='button'
                href={episode.spotify.external_urls.spotify}
                target='_blank'
                rel='noreferrer'
                onClick={() =>
                  fireEvent('ExternalClick', product, {
                    to: episode.spotify.external_urls.spotify
                  })
                }
              >
                <span className='icon has-text-spotify'>
                  <FontAwesomeIcon icon={faSpotify} />
                </span>
                <span>{i18n.podcast.channels.spotify}</span>
              </a>
              <a
                className='button'
                href={episode.apple.trackViewUrl}
                target='_blank'
                rel='noreferrer'
                onClick={() =>
                  fireEvent('ExternalClick', product, {
                    to: episode.apple.trackViewUrl
                  })
                }
              >
                <span className='icon has-text-apple-podcast'>
                  <FontAwesomeIcon icon={faPodcast} />
                </span>
                <span>{i18n.podcast.channels.apple}</span>
              </a>
              <a
                className='button'
                href={`https://www.youtube.com/watch?v=${episode.youtube.snippet.resourceId.videoId}&list=${episode.youtube.snippet.playlistId}`}
                target='_blank'
                rel='noreferrer'
                onClick={() =>
                  fireEvent('ExternalClick', product, {
                    to: `https://www.youtube.com/watch?v=${episode.youtube.snippet.resourceId.videoId}&list=${episode.youtube.snippet.playlistId}`
                  })
                }
              >
                <span className='icon has-text-youtube'>
                  <FontAwesomeIcon icon={faYoutube} />
                </span>
                <span>{i18n.podcast.channels.youtube}</span>
              </a>
            </p>
            <div className='tabs is-small is-centered is-boxed is-margin-bottom-0'>
              <ul>
                <li className={clsx({ 'is-active': !isVideo })}>
                  <a onClick={() => setIsVideo(false)}>
                    <span>{i18n.podcast.audio}</span>
                  </a>
                </li>
                <li className={clsx({ 'is-active': isVideo })}>
                  <a onClick={() => setIsVideo(true)}>
                    <span>{i18n.podcast.video}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className='columns is-centered is-margin-bottom-0'>
              {isVideo ? (
                <div className='column is-12-mobile is-8-tablet is-6-desktop'>
                  <figure className='image is-16by9 is-margin-left-0 is-margin-right-0 is-margin-bottom-0'>
                    <iframe
                      className='has-ratio'
                      width='624'
                      height='351'
                      src={`https://open.spotify.com/embed/episode/${episode.spotify.id}/video`}
                      frameBorder='0'
                      allowfullscreen
                      allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
                    ></iframe>
                  </figure>
                </div>
              ) : (
                <div className='column is-12-mobile is-10-tablet is-8-desktop'>
                  <iframe
                    className='has-ratio'
                    width='100%'
                    height='152'
                    src={`https://open.spotify.com/embed/episode/${episode.spotify.id}/`}
                    frameBorder='0'
                    allowfullscreen
                    allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
                  ></iframe>
                </div>
              )}
            </div>
            <p className='help has-text-centered is-margin-top-0'>
              {i18n.podcast.share}
            </p>
            <p className='buttons are-small is-centered is-margin-bottom-4'>
              <a
                className='button is-whatsapp is-inverted'
                href={`whatsapp://send?text=${encodeURIComponent(
                  siteUrl + pathname
                )}`}
                target='_blank'
                rel='noreferrer'
                onClick={() =>
                  fireEvent('ExternalClick', product, {
                    to: 'whatsapp'
                  })
                }
              >
                <span className='icon'>
                  <FontAwesomeIcon icon={faWhatsapp} />
                </span>
              </a>
              <a
                className='button is-facebook is-inverted'
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  siteUrl + pathname
                )}`}
                target='_blank'
                rel='noreferrer'
                onClick={() =>
                  fireEvent('ExternalClick', product, {
                    to: 'facebook'
                  })
                }
              >
                <span className='icon'>
                  <FontAwesomeIcon icon={faFacebookF} />
                </span>
              </a>
              <a
                className='button is-dark is-inverted'
                href={`mailto:?subject=${episode.spotify.name}&body=${
                  siteUrl + pathname
                }`}
                target='_blank'
                rel='noreferrer'
                onClick={() =>
                  fireEvent('ExternalClick', product, {
                    to: 'email'
                  })
                }
              >
                <span className='icon'>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </a>
              <a
                className='button is-dark is-inverted'
                onClick={async () => {
                  try {
                    await navigator.share({
                      title: i18n.podcast.title,
                      text: episode.spotify.name,
                      url: siteUrl + pathname
                    })
                    fireEvent('ExternalClick', product, {
                      to: 'share'
                    })
                  } catch (_) {
                    void 0
                  }
                }}
              >
                <span className='icon'>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body is-padding-top-0'>
          <div className='container content'>
            <h4 className='has-text-centered is-margin-bottom-6'>
              {i18n.podcast.episodeDescription}
            </h4>
            <div className='columns is-multiline is-mobile is-centered'>
              <div className='column is-full-real-mobile is-10-mobile is-8-tablet is-5-desktop'>
                <figure className='image is-1by1 is-fullwidth is-margin-left-0 is-margin-right-0'>
                  <img src={episode.spotify.images[0].url} />
                </figure>
              </div>
              <div className='column is-6-desktop is-full-tablet is-full-mobile is-offset-1-desktop is-flex is-flex-direction-column is-align-items-center is-justify-content-center'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: episode.spotify.html_description
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <nav className='level is-mobile'>
              <div className='level-item is-justify-content-start'>
                {episode.prev && (
                  <Link
                    to={episode.prev.link}
                    className='is-flex is-align-items-center'
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size='3x'
                      className='is-margin-right-4'
                    />
                    {episode.prev.title}
                  </Link>
                )}
              </div>
              <div className='level-item is-justify-content-end'>
                {episode.next && (
                  <Link
                    to={episode.next.link}
                    className='is-flex is-align-items-center'
                  >
                    {episode.next.title}
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      size='3x'
                      className='is-margin-left-4'
                    />
                  </Link>
                )}
              </div>
            </nav>
            <p className='has-text-centered'>
              <Link to='/academy/podcast/'>
                {i18n.podcast.backToPodcastPage}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EpisodeTemplate
